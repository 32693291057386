<template>
  <div class="d-flex position-relative" v-on-clickaway="away">
    <div
      class="bg-white shadow-lg overflow-auto side-menu"
      v-bind:class="{ 'reset': toggle != null }"
    >
      <perfect-scrollbar class="h-100">
        <ul class="list-unstyled m-0 menu">
          <li v-permission="['administrator','office']" :class="{ active: isActive('Dashboard') }">
            <a @click="redirect('/admin','Dashboard')" class="btn btn-link btn-hover">
              <i class="fas fa-hover fa-chart-line fa-2x"></i>
              <span class="d-block text-item">Dashboard </span>
            </a>
          </li>
          <li v-for="(item ,index) in menu" :key="index" :class="{ active: isActive(item.name) }">
            <a v-permission="item.permission" @click="redirect(item.route,item.name)" class="btn btn-link btn-hover">
              <i class="fas fa-hover fa-2x" v-bind:class="item.icon"></i>
              <span class="d-block text-capitalize text-item" :class="[item.class]">{{item.name}}</span>
            </a>
          </li>
        </ul>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";

export default {
  data() {
    return {
      toggle: null,
      activeItem: "Dashboard",
      menu: [        
        
        {
          name: "clients",
          icon: "fa-users",
          permission: ["administrator", "employee", "office"],
          route: "/admin/corporate"
        },
        {
          name: "licenses",
          icon: "fa-ticket-alt",
          permission: ["administrator", "employee", "office"],
          route: "/admin/license"
        },
        {
          name: "bank Statements",
          icon: "fa-file-invoice",
          class: 'one-word-per-line',
          permission: ["employee","office","administrator"],
          route: "/admin/statement"
        },
        {
          name: "sales tax",
          icon: "fa-calculator-alt",
          permission: ["administrator", "employee","office"],
          route: "/admin/sale-tax"
        },
        {
          name: "payroll tax",
          icon: "fa-sack-dollar",
          permission: ["administrator", "employee", "office"],
          route: "/admin/payroll-tax"
        },
        {
          name: "corp tax",
          icon: "fa-chart-pie",
          permission: ["administrator", "employee", "office"],
          route: "/admin/corp-tax"
        },
        {
          name: "Insurance",
          icon: "fa-ballot",
          permission: ["employee","office","administrator"],
          route: "/admin/insurance"
        },
        {
          name: "Form Nec",
          icon: "fa-list-ol",
          permission: ["employee","office","administrator"],
          route: "/admin/form-nec"
        },
        {
          name: "Document Manager",
          icon: "fa-folder-open",
          permission: ["administrator", "employee", "office","shareholder"],
          route: "/admin/document-manager"
        }
      ]
    };
  },
  directives: {
    onClickaway: onClickaway
  },
  methods: {
    active(name) {
      this.activeItem = name;
      return (this.toggle = this.toggle !== name ? name : null);
    },
    redirect(path,name) {
      this.toggle = null;
      this.activeItem = name;
      this.$router.push(path).catch(() => {});
    },
    isActive: function(menu) {
      return this.activeItem === menu;
    },
    away() {
      this.toggle = null;
    }
  }
};
</script>

<style lang="scss">
.side-menu {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  width: 112px;
  z-index: 4;

  .menu {
    .active {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 3.5px;
        height: 80px;
        background: #145388;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 10px;
      }
    }

    li {
      a {
        display: block;
        padding: 20px 0;
        text-align: center;
        border-bottom: 1px solid #f3f3f3;
      }
    }

    .logout {
      bottom: 0;
    }
  }
}

.reset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none !important;
}

.side-sub-menu {
  top: 0;
  left: 112px;
  width: 250px;
  z-index: 3;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.fa-hover:hover {
    color:#fff !important;
}

.fa-hover{
    color: #145388 !important;
}

.text-item{
  color:#666666;
}

.btn-hover:hover{
  .fa-hover {
    color:#fff !important;
    }
  .text-item{
    color: white;
  }
  background-color: #145388 !important;
}
.one-word-per-line{
  word-spacing: 80px; 
}
</style>

